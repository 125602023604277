import './styles/App.css';
import AppProvider from './providers/app';
import AppRoutes from './routes';
import MaintenanceMode from './components/shared/maintenance-mode/MaintenanceMode';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-RX7YZTR7JX');
function App() {
    const maintenanceMode = false;
    //!!Disable right click on entire site
    // document.addEventListener('contextmenu', (event) => {
    //     event.preventDefault();
    // });
    return (
        <AppProvider>
            {maintenanceMode ? <MaintenanceMode /> : <AppRoutes />}
        </AppProvider>
    );
}

export default App;
